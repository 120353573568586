import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { Observable } from 'rxjs';
import { OtherSpr } from '../../../../core/interfaces/interfaces';
import { FormControl } from '@angular/forms';
import { TaskService } from '../core/task.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-task-dialog-change-manager',
  templateUrl: './task-dialog-change-manager.component.html',
  styleUrls: ['./task-dialog-change-manager.component.scss'],
  providers: [TaskService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskDialogChangeManagerComponent implements OnInit {
  workmanList$: Observable<OtherSpr[]>;
  workmanListForm: FormControl;

  constructor(
    private task: TaskService,
    private dialogRef: MatDialogRef<TaskDialogChangeManagerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {}

  ngOnInit(): void {
    this.loadWorkmanList();
  }

  loadWorkmanList(): void {
    this.workmanList$ = this.task
      .loadManagerTask()
      .pipe(
        tap(
          () => (this.workmanListForm = new FormControl(this.dialogData.userId))
        )
      );
  }

  onSetChangeWorkmanId(): void {
    this.task
      .onSetChangeWorkmanId(this.workmanListForm.value, this.dialogData.taskId)
      .subscribe(() => {
        this.dialogRef.close({ code: 200 });
      });
  }
}
