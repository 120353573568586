import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IActionState, SrvService } from 'repositories';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'lib-list-table',
  templateUrl: 'list-table.component.html',
})
export class ListTableComponent implements OnInit {
  @Input() resultData: any;
  @Input() selected = false;
  @Input() editNomen = false;
  @Input() selectedProduct = [];
  @Input() searchFieldList: string[] = [];
  @Input() actionState: IActionState = {
    edit: false,
    delete: false,
  };

  @Output() colClickEvent = new EventEmitter<{ row: any; field: string }>();
  @Output() rowClickEvent = new EventEmitter<{ row: any }>();
  @Output() editClickEvent = new EventEmitter<{ row: any }>();
  @Output() infoClickEvent = new EventEmitter<{ row: any }>();
  @Output() showMoreClickEvent = new EventEmitter<{ row: any }>();
  @Output() deleteClickEvent = new EventEmitter<{ row: any }>();
  @Output() uploadClickEvent = new EventEmitter<{ row: any }>();
  @Output() addClickEvent = new EventEmitter<{ row: any }>();
  @Output() offEvent = new EventEmitter<{ row: any }>();
  @Output() setEvent = new EventEmitter<{ row: any }>();
  @Output() selectProductEvent = new EventEmitter();
  @Output() setNomen = new EventEmitter<any[]>();
  @Output() setReplaceNomen = new EventEmitter<any[]>();

  @ViewChild('slideNomen') slideNomen: MatSlideToggle;

  isValidate: FormControl = new FormControl(false);
  form: FormGroup = new FormGroup({
    nomenRu: new FormControl(''),
    nomenUa: new FormControl(''),
  });
  formReplace: FormGroup = new FormGroup({
    fromRu: new FormControl('', Validators.required),
    toRu: new FormControl('', Validators.required),
    fromUa: new FormControl('', Validators.required),
    toUa: new FormControl('', Validators.required),
  });

  colClick(row: any, field: string): void {
    this.colClickEvent.emit({ row, field });
  }

  rowClick(row: any): void {
    this.rowClickEvent.emit({ row });
  }

  edit(rowData: any): void {
    this.editClickEvent.emit(rowData);
  }

  info(rowData: any): void {
    this.infoClickEvent.emit(rowData);
  }

  showMore(rowData: any): void {
    this.showMoreClickEvent.emit(rowData);
  }

  delete(rowData: any): void {
    this.deleteClickEvent.emit(rowData);
  }

  upload(rowData: any): void {
    this.uploadClickEvent.emit(rowData);
  }

  addProd(rowData: any): void {
    this.addClickEvent.emit(rowData);
  }

  onClick(rowData: any): void {
    this.setEvent.emit(rowData);
  }

  offClick(rowData: any): void {
    this.offEvent.emit(rowData);
  }

  onRowSelect(): void {
    this.selectProductEvent.emit(this.selectedProduct);
  }

  constructor(private srv: SrvService) {}

  setNomenToProd(): boolean {
    if (this.form.invalid) {
      this.srv.errorMessage('Неверно заполнена форма');
      return false;
    }

    if (this.selectedProduct.length === 0) {
      this.srv.errorMessage('Выберите товар');
      return false;
    }

    const arr = this.selectedProduct;
    this.selectedProduct = [];
    this.setNomen.emit({
      prod: arr.map((item) => item.id),
      ...this.form.value,
    });
  }

  setReplaceToProd(): void {
    if (this.selectedProduct.length === 0) {
      this.srv.errorMessage('Выберите товар');
      return;
    }
    if (this.formReplace.invalid) {
      this.srv.errorMessage('Неверно заполнена форма');
      return;
    }
    const arr = this.selectedProduct;
    this.selectedProduct = [];

    this.setReplaceNomen.emit({
      prod: arr.map((item) => item.id),
      ...this.formReplace.value,
    });
  }

  setValidator(): void {
    if (this.isValidate.value) {
      this.form.controls.nomenRu.clearValidators();
      this.form.controls.nomenUa.clearValidators();
      this.form.controls.nomenRu.updateValueAndValidity();
      this.form.controls.nomenUa.updateValueAndValidity();

      this.formReplace.controls.fromRu.clearValidators();
      this.formReplace.controls.toRu.clearValidators();
      this.formReplace.controls.toUa.clearValidators();
      this.formReplace.controls.fromUa.clearValidators();

      this.formReplace.controls.fromRu.updateValueAndValidity();
      this.formReplace.controls.toRu.updateValueAndValidity();
      this.formReplace.controls.toUa.updateValueAndValidity();
      this.formReplace.controls.fromUa.updateValueAndValidity();
    } else {
      this.form.controls.nomenRu.setValidators(Validators.required);
      this.form.controls.nomenUa.setValidators(Validators.required);
      this.form.controls.nomenRu.updateValueAndValidity();
      this.form.controls.nomenUa.updateValueAndValidity();

      this.formReplace.controls.fromRu.setValidators(Validators.required);
      this.formReplace.controls.toRu.setValidators(Validators.required);
      this.formReplace.controls.fromUa.setValidators(Validators.required);
      this.formReplace.controls.toUa.setValidators(Validators.required);
      this.formReplace.controls.fromRu.updateValueAndValidity();
      this.formReplace.controls.toRu.updateValueAndValidity();
      this.formReplace.controls.toUa.updateValueAndValidity();
      this.formReplace.controls.fromUa.updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.setValidator();
  }
}
