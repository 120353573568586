import { NgModule } from '@angular/core';
import { ListTableComponent } from './list-table.component';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { SharedProviderCountTableModule } from 'shared-provider-count-table';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [ListTableComponent],
  imports: [
    TableModule,
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    InputTextModule,
    MatButtonModule,
    MatMenuModule,
    SharedProviderCountTableModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
  ],
  exports: [ListTableComponent],
})
export class ListTableModule {}
