import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RequestBody, SalaryList } from '../../../core/interfaces/interfaces';
import { ApiService } from '../../../core/services/api.service';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MatDialog } from '@angular/material/dialog';
import { DialogInfoComponent } from './dialog-info/dialog-info.component';
import { SrvService } from 'repositories';

@Component({
  selector: 'app-salary-page',
  templateUrl: './salary-page.component.html',
  styleUrls: ['./salary-page.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'ru-Ru' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SalaryPageComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subscription: Subscription;
  salaryList: SalaryList[] = [];
  footer: SalaryList;
  cols: any[];
  managerArr: string; // строка все менеджеров в за диапазон
  loading: boolean;

  constructor(
    private api: ApiService,
    public srv: SrvService,
    private dialog: MatDialog
  ) {
    this.footer = {
      check: 0,
      class: '',
      conv: '',
      manager: '',
      managerId: 0,
      presentations: 0,
      sales: 0,
      setAll: 0,
      successfulOrders: 0,
      uniqAllProd: 0,
      uniqReturnProd: 0,
    };
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      start: new FormControl(new Date(), [Validators.required]),
      end: new FormControl(new Date(), [Validators.required]),
    });
  }

  getGrossInfo(): void {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    const formVal = this.form.value;

    const q: RequestBody = {
      modelName: 'Analytics',
      calledMethod: 'getSalaryProduct',
      methodProperties: {
        type: 1,
        startDate: this.srv.convertDate(formVal.start),
        endDate: this.srv.convertDate(formVal.end),
      },
    };

    this.subscription = this.api.post_jwt(q).subscribe((spr) => {
      this.cols = spr.headerList;
      this.salaryList = spr.salaryList;
      this.footer = spr.footer;
      this.managerArr = spr.managerArr;
      this.loading = false;
    });
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  loadMoreInfo(index: number, row?: SalaryList, managerList?: string): void {
    const formVal = this.form.value;

    switch (index) {
      case 0:
        this.srv.primaryMessage('Нет данных для разворота');
        break;
      case 3:
        this.srv.primaryMessage(
          'Ячейка расчитывается математически (Кол-во успешных заказов * 100 / Кол-во презентаций)'
        );
        break;
      case 5:
        this.srv.primaryMessage(
          'Ячейка расчитывается математически (Объем сбыта / Кол-во успешных заказов)'
        );
        break;

      /*     case 7:
        this.srv.openSnackBar(
          'Ячейка расчитывается математически (Кол-во Возвратов уникальных товаров * 100 / Кол-во аквтивных уникальных товаров)',
          'snack-bar-primary',
          'right',
          'top',
        );
        break;*/
      default:
        const dialog = this.dialog.open(DialogInfoComponent, {
          maxHeight: '90vh',
          maxWidth: '90%',
          minWidth: '40%',
          width: 'auto',
          data: {
            col: index,
            manager: managerList ? managerList : row.managerId,
            startDate: this.srv.convertDate(formVal.start),
            endDate: this.srv.convertDate(formVal.end),
          },
        });
        break;
    }
  }
}
