import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TaskIndicator } from '../../interface';
import { SrvService } from 'repositories';

@UntilDestroy()
@Component({
  selector: 'lib-task-indicator',
  templateUrl: './task-indicator.component.html',
})
export class TaskIndicatorComponent implements OnInit {
  @Input() taskIndicator: TaskIndicator;
  @Output() showIndicator = new EventEmitter<boolean>();

  showRandomProdList: number[];

  constructor(private srv: SrvService) {}

  ngOnInit(): void {
    this.srv.userPerm
      .pipe(
        filter((val) => val != null),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        setTimeout(() => {
          this.showRandomProdList = value.permission?.filter(
            (val) => +val === 66
          );
        }, 500);
      });
  }

  showTaskList(): void {
    this.showIndicator.emit(true);
  }
}
