import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
} from '@angular/core';
import { filter, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WarehouseChatListComponent } from '../../../../../product-request/src/lib/warehouse-chat-list/warehouse-chat-list.component';
import { OrderChatListComponent } from '../../../../../product-request/src/lib/order-chat-list/order-chat-list.component';
import { RandomRequestPaymentListComponent } from '@crm/sale/random-product/random-request-payment-list/random-request-payment-list/random-request-payment-list.component';
import { INotificationMessage, SrvService } from 'repositories';
import { NotificationService } from '../../core/service/notification.service';

@Component({
  selector: 'lib-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit, OnDestroy {
  @Input() toast: INotificationMessage;
  @Input() i: number;
  @Input() id: number;
  @Input() btnFunk: number;
  @Input() hash: string;

  @Output() remove = new EventEmitter<number>();
  destroy = new ReplaySubject();

  setPermOnRequest: number[];

  constructor(
    private ntf: NotificationService,
    private dialog: MatDialog,
    private srv: SrvService
  ) {}

  ngOnInit(): void {
    this.srv.userPerm.pipe(filter((val) => val != null)).subscribe((value) => {
      this.setPermOnRequest = value.permission?.filter((val) => +val === 38);
    });
  }

  setSubmitNtf(id): void {
    this.ntf.setSubmitNtf(id).pipe(takeUntil(this.destroy)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  setFunkBtn(btnFunk: number, hash: string): void {
    switch (+btnFunk) {
      case 1:
        if (this.setPermOnRequest.length > 0) {
          this.dialog.open(OrderChatListComponent, {
            data: {
              id: hash,
              katalNumber: '',
              brand: '',
              typeChat: 2,
            },
            maxHeight: '94vh',
            minWidth: '50vw',
          });
        } else {
          this.dialog.open(WarehouseChatListComponent, {
            data: {
              id: hash,
              katalNumber: '',
              brand: '',
              typeChat: 2,
            },
            maxHeight: '94vh',
            minWidth: '50vw',
          });
        }
        break;
      case 2:
        this.dialog.open(RandomRequestPaymentListComponent, {
          maxHeight: '94vh',
          minWidth: '50vw',
        });
        break;
    }
  }
}
