<mat-card-title>
  <div class='row justify-content-between mb-3'>
    <div class='col-auto align-self-center'>

    </div>
    <div class='col-auto'>
      <button
        type='button'
        mat-icon-button
        class='btn btn-primary btn-sm'
        matTooltip='Закрыть'
        matTooltipPosition='left'
        matTooltipClass='tooltip'
        matDialogClose
      >
        <img src='assets/img/icon/close/Icon_Close.svg' alt='close' />
      </button>
    </div>
  </div>
</mat-card-title>

<div class='row'>
  <div class='col text-center'>
    <h2><b>На дату {{dialogData.planDateSend}} і на отримувача {{dialogData.recipient}} є ще замовлення: </b></h2>
  </div>
</div>
<table class='table-striped table table-bordered'>
  <thead>
  <th></th>
  <th>Заказ</th>
  <th>Дата создания</th>
  <th>Дата подтверждения</th>
  <th>Менеджер</th>
  <th>Статус</th>
  <th>Товары</th>
  </thead>
  <tbody>
  <tr *ngFor='let item of dialogData.orderList; let index  = index'>
    <td>{{index+1}}</td>
    <td>
      <a class='link' target='_blank' [routerLink]="['crm','order-card',item.zakaz]">{{item.zakaz}}</a>
    </td>
    <td>{{item.dateCreate}}</td>
    <td>{{item.dateConfirm}}</td>
    <td>{{item.manager}}</td>
    <td>{{item.action}}</td>
    <td [innerHtml]='item.productList'></td>
  </tr>
  </tbody>
</table>


<div class='row justify-content-center mt-5'>
  <div class='col text-center'>
    <button mat-button class='btn btn-success' (click)='pack()'>
      Упакувати вибране замовлення окремо
    </button>
  </div>
  <div class='col text-center'>
    <button mat-button class='btn btn-danger' matDialogClose>
      Чекати збору всіх замовлень
    </button>
  </div>
</div>
