import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderDopSaleComponent } from './header-dop-sale.component';
import { TitleModalWindowModule } from '../../shared/title-modal-window/title-modal-window.module';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { HeaderDopSaleListModule } from '../header-dop-sale-list/header-dop-sale-list.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { SelectWithFilterModule } from 'shared';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [{ path: '', component: HeaderDopSaleComponent }];

@NgModule({
  declarations: [HeaderDopSaleComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    TitleModalWindowModule,
    MatCardModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatDividerModule,
    HeaderDopSaleListModule,
    MatExpansionModule,
    SelectWithFilterModule,
  ],
  exports: [HeaderDopSaleComponent],
})
export class HeaderDopSaleModule {}
