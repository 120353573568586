import * as Sentry from '@sentry/angular';
const DOMAIN = 'srv.test-server-project.site'; // Используем плейсхолдер

export const environment = {
  production: true,
  sentryDNS:
    'https://b91404f15f3d4b08bb25be486dfd111e@o1301142.ingest.sentry.io/6536748',
  token: 'e45r9823ejjdthng052e23rmuh',
  url: `https://${DOMAIN}/api/`,
  domen: `${DOMAIN}`,
  url_jwt: `https://${DOMAIN}/api_jwt/`,
  urlGetSite: `https://${DOMAIN}/api_get/`,
  api_jwt_file_fid_delete: `https://${DOMAIN}/api_jwt_file_fid_delete/`,
  api_jwt_file_fid_create: `https://${DOMAIN}/api_jwt_file_fid_create/`,
  api_jwt_file_fid_disapproved: `https://${DOMAIN}/api_jwt_file_fid_disapproved/`,
  url_jwt_file: `https://${DOMAIN}/api_jwt_file/`,
  api_jwt_file_provider: `https://${DOMAIN}/api_jwt_file_provider/`,
  api_jwt_file_img_brand: `https://${DOMAIN}/api_jwt_file_img_brand/`,
  api_jwt_file_img_prod: `https://${DOMAIN}/api_jwt_file_img_prod/`,
  api_jwt_file_request_ads: `https://${DOMAIN}/api_jwt_file_request_ads/`,
  api_jwt_file_price: `https://${DOMAIN}/api_jwt_file_price/`,
  api_jwt_file_product_card: `https://${DOMAIN}/api_jwt_file_product_card/`,
  api_jwt_file_fid_label2: `https://${DOMAIN}/api_jwt_file_fid_label2`,
};
