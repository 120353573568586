import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OtherSpr, RequestBody } from '@core/interfaces/interfaces';
import { ApiService } from '@core/services/api.service';

@Injectable()
export class OcService {
  constructor(private api: ApiService) {}

  loadContactInfo(orderNumber): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadContactInfo',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  loadDeliveryInfo(orderNumber): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadDeliveryInfo',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSpecification(orderNumber): Observable<any[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadSpecification',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  loadOrderCommentInfo(orderNumber): Observable<any[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadOrderCommentInfo',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  loadNewPostWarehouse(city): Observable<any[]> {
    const q: RequestBody = {
      modelName: 'Delivery',
      calledMethod: 'getWarehouseList',
      methodProperties: {
        type: 1,
        city,
      },
    };
    return this.api.post_jwt(q);
  }

  onSearchCity(city): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Delivery',
      calledMethod: 'getCityList',
      methodProperties: {
        type: 1,
        city,
      },
    };
    return this.api.post_jwt(q);
  }

  getSelectCity(value): Observable<any[]> {
    const q: RequestBody = {
      modelName: 'Delivery',
      calledMethod: 'getSelectCity',
      methodProperties: {
        type: 1,
        value,
      },
    };
    return this.api.post_jwt(q);
  }

  changeOrderCar(carId: number, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveNewOrderCar',
      methodProperties: {
        type: 1,
        carId,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setRecipientOrder(recipientId: number, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveRecipient',
      methodProperties: {
        type: 1,
        recipientId,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setDeliveryType(delivery: number, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveDelivery',
      methodProperties: {
        type: 1,
        delivery,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setPaymentType(pay: number, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSavePayment',
      methodProperties: {
        type: 1,
        pay,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setPlanDateSend(planDateSend: any, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveDateSend',
      methodProperties: {
        type: 1,
        planDateSend,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setPlanDateSendWork(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'setPlanDateSendWork',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  saveSendComment(comment: string, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveSendComment',
      methodProperties: {
        type: 1,
        comment,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  saveOrderComment(comment: string, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveOrderComment',
      methodProperties: {
        type: 1,
        comment,
        typeComment: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setWaitPayment(waitPay: number, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSavePaymentWait',
      methodProperties: {
        type: 1,
        waitPay,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  saveOrderCity(city: OtherSpr, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveDeliveryCity',
      methodProperties: {
        type: 1,
        order,
        city: city.text,
        cityRef: city.value,
      },
    };
    return this.api.post_jwt(q);
  }

  saveWarehouseCity(city: any, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveDeliveryWarehouse',
      methodProperties: {
        type: 1,
        order,
        number: city.number,
        warehouse: city.text,
        warehouseRef: city.value,
      },
    };
    return this.api.post_jwt(q);
  }

  saveDeliveryInfo(deliveryInfo: string, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSaveDeliveryInfo',
      methodProperties: {
        type: 1,
        order,
        deliveryInfo,
      },
    };
    return this.api.post_jwt(q);
  }

  loadDeliveryList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprOtherSpr',
      methodProperties: {
        type: 1,
        listId: 16,
      },
    };
    return this.api.post_jwt(q);
  }

  loadBankCardList(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getBackCard',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  loadBankCardListUser(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getBackCardUser',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  getBackCardAnother(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getBackCardAnother',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  loadPaymentList(order): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadPaymentList',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSprWhi(obj): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprSelectionInfoList',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSprWhiCancel(order, clientType): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadSprWhiCancel',
      methodProperties: {
        type: 1,
        order,
        clientType,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSprSubReasonCancel(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'getSprCancelOrderInfoList',
      methodProperties: {
        type: 1,
        listId: 44,
      },
    };
    return this.api.post_jwt(q);
  }

  loadBankCard(orderNumber): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadBankCard',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSprGroup(): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadSprGroup',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  addNewRows(order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'addNewRowToOrder',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  searchOENum(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'searchOENum',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  setToWork(order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'setToWork',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setToWorkSpec(orderNumber: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Avto24',
      calledMethod: 'setOrderToNew',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  onDeleteProductToOrder(id: number, order: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onDeleteRowToOrder',
      methodProperties: {
        type: 1,
        order,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  loadProductInfo(id: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadProductInfo',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  loadProductComment(id): Observable<any[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadProductComment',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  readMessage(id): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'readMessageComment',
      methodProperties: {
        type: 1,
        id,
      },
    };
    return this.api.post_jwt(q);
  }

  sendNewComment(id, comment): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'sendNewComment',
      methodProperties: {
        type: 1,
        id,
        comment,
      },
    };
    return this.api.post_jwt(q);
  }

  loadReturnInfo(): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'loadReturnInfo',
      methodProperties: {
        type: 1,
      },
    };
    return this.api.post_jwt(q);
  }

  loadSelfInfo(order): Observable<any> {
    const q: RequestBody = {
      modelName: 'SprDirect',
      calledMethod: 'loadSelfInfo',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  getSaleProduct(saleId, priceCost, brand, productId): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'getSaleProduct',
      methodProperties: {
        type: 1,
        saleId,
        brand,
        priceCost,
        productId,
      },
    };
    return this.api.post_jwt(q);
  }

  saveCardProduct(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'saveCardProduct',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onSetSelection(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSetSelection',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onSetCancel(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSetCancel',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  onSetConfirm(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSetConfirm',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  sendSMS(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Sms',
      calledMethod: 'sendRandomSms',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  sendSMSTTN(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Sms',
      calledMethod: 'setSmsTtnDelivery',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  sendSMSCard(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Sms',
      calledMethod: 'setSmsBankCard',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  setSmsBankCardUser(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Sms',
      calledMethod: 'setSmsBankCardUser',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  sendSMSReturn(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Sms',
      calledMethod: 'setSmsReturnInfo',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  sendSMSSelf(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Sms',
      calledMethod: 'setSmsSelfRemoval',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  sendReturnTtn(obj): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'sendReturnTtn',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  setSaleRate(num: number, orderNumber: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'setSaleRate',
      methodProperties: {
        type: 1,
        num,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  copyOrder(orderNumber: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onCopyOrder',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  loadInfoSend(orderNumber: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'getSendInfo',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  saveNewTTN(ttn: string, orderNumber: number): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'saveNewTTN',
      methodProperties: {
        type: 1,
        ttn,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  onSetPrintCheck(orderList: string): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onSetPrintCheck',
      methodProperties: {
        type: 1,
        orderList,
      },
    };
    return this.api.post_jwt(q);
  }

  createCallBack(order): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onCreateNewTaskCallBack',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  createCallBackToLatter(order): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'onCreateNewTaskCallBackToLatter',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  createResponseViber(order): Observable<any> {
    const q: RequestBody = {
      modelName: 'Task',
      calledMethod: 'createResponseViber',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  onLoadHistory(order): Observable<any[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'onLoadHistory',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  loadTotalTask(order): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadTotalTask',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  loadTotalPretension(order): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadTotalPretension',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  setCall(phone, orderNumber?): Observable<any> {
    const q: RequestBody = {
      modelName: 'VoIp',
      calledMethod: 'setCall',
      methodProperties: {
        type: 1,
        phone,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCheckInfo(orderList: string): Observable<any[]> {
    const q: RequestBody = {
      modelName: 'OrderCard',
      calledMethod: 'printCheck',
      methodProperties: {
        type: 1,
        orderList,
      },
    };
    return this.api.post_jwt(q);
  }

  getCardFromVin(vin, hash): Observable<OtherSpr[]> {
    const q: RequestBody = {
      modelName: 'Client',
      calledMethod: 'getCardFromVin',
      methodProperties: {
        type: 1,
        vin,
        hash,
      },
    };
    return this.api.post_jwt(q);
  }

  setMiddleName(orderNumber: number): Observable<{ code: number }> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'setMiddleName',
      methodProperties: {
        type: 1,
        orderNumber,
      },
    };
    return this.api.post_jwt(q);
  }

  updateCallInfo(callId, orderNumber, comment): Observable<any> {
    const q: RequestBody = {
      modelName: 'VoIp',
      calledMethod: 'updateCallInfo',
      methodProperties: {
        type: 2,
        callId,
        orderNumber,
        comment,
      },
    };
    return this.api.post_jwt(q);
  }

  loadLastUserOrder(order): Observable<any[]> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadLastUserOrder',
      methodProperties: {
        type: 1,
        order,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCheckList(obj: { orderNumber: number }): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadCheckList',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  setRefreshCheckSms(obj: { checkId: any }): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'setRefreshCheckSms',
      methodProperties: {
        type: 1,
        ...obj,
      },
    };
    return this.api.post_jwt(q);
  }

  loadCheckProductOnCar(param: { orderNumber: any }): Observable<any> {
    const q: RequestBody = {
      modelName: 'Order',
      calledMethod: 'loadCheckProductOnCar',
      methodProperties: {
        type: 1,
        ...param,
      },
    };
    return this.api.post_jwt(q);
  }
}
